// bootstrap colors
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$grey: #e2e2e2;


// The af_green color, a little too light for my tastes
//$af_green: #3dc99a;
$af_orange: #ffa01e;

// This is a darker version of the af_green
$primary: #258364;

// Changing the primary color also changes the link color,
// I don't like that particularly much so we reset that here
$link-color: $blue;


// So we can keep the cards at 100% height of the rows
$card-height: 100%;

@import "./node_modules/bootstrap/scss/bootstrap";

.custom-error-msg {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

// default system fonts. From https://css-tricks.com/snippets/css/system-font-stack/
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

// Position hero image with fade to white into rest of page
.hero-image {
  color: black;
  background-image: linear-gradient(rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 1)), url("/images/hero-image.png");
  background-position: 30% 65%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  position: relative;
}

.navbar {
  min-height: 6vh;
  // muted version of AF color
  background-color: #ffa01e94;
}

.max-width-250 {
  max-width: 250px;
}

.bg-grey {
  background: $grey;
}

//wvf logo is completely different format than the others
.wvf-logo {
  height: 10rem !important;
}

//DN logo has the text way farther towards the bottom, so we gotta adjust it so it don't look weird
.dn-logo {
  height: 8rem !important;
}

// Af logo is positioned alone at the top so needs a little extra love
.af-logo {
  height: 5rem !important;;
}

.dgs-logo {
  height: 8rem !important;
}

.text-primary-color {
  color: $primary;
}

.divider {
  margin-bottom: 1rem;
}